import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ArticleTile from '../components/ArticleTile'
import Headline from '../components/Headline'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import IntroGradient from '../components/IntroGradient'
import { sGrid } from '../components/ServicesIndex'
import Menu from '../components/Menu'
import LogoFixed from '../components/LogoFixed'

const Blog = () => {
  const { articles, count } = useStaticQuery(graphql`
    {
      articles: allContentfulArticle(sort: { fields: date, order: DESC }) {
        nodes {
          title
          desc
          image {
            gatsbyImageData(
              quality: 100
              aspectRatio: 1.33
              placeholder: BLURRED
            )
          }
          type {
            name
          }
          slug
        }
      }
      count: allContentfulArticle {
        nodes {
          slug
        }
      }
    }
  `)

  return (
    <>
      <Seo title='Baza Wiedzy' desc='' url='/blog/' />
      <Menu />
      <LogoFixed />
      <IntroGradient />
      <Headline back='Wiedza'>
        <h1>
          Sprawdź jeden z <mark>ponad {count.nodes.length}</mark> najnowszych{' '}
          <br />
          <mark>artykułów</mark> z dziedziny biznesu i marketingu
        </h1>
      </Headline>
      <div css={sGrid}>
        {articles.nodes.map((article, id) => (
          <ArticleTile key={id} data={article} />
        ))}
      </div>
      <Footer />
    </>
  )
}

export default Blog
